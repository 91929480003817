import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormItemContainer from './FormItemContainer';
import { useEffect, useState } from 'react';

const apiBaseUrl = "https://prefs.nextgenauction.com"

function VehicleTypes(props) {
    console.log("Vehicle types...");
    //first get the available types:
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleChecks, setVehicleChecks] = useState({});
    //fetch it

    const converBools = (obj) => {
        let newObj = {};
        Object.keys(obj).forEach(key => {
            newObj[key] = Boolean(obj[key]);
        });        
        return newObj;
    }

    const getPreferences = async () => {
        console.log("Getting preferences...");
        let response = await fetch(`${apiBaseUrl}/getTypePreferences?dealerKey=${props.dealerKey}`)
        let data = await response.json();
        setVehicleChecks(converBools(data));
        setVehicleTypes(Object.keys(data));
    }

    const saveTypes = async () => {
        console.log("Saving types...");
        let response = await fetch(`${apiBaseUrl}/setTypePreferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vehicleTypes: vehicleChecks,
                dealerKey: props.dealerKey
            })
        });
        let data = await response.json();
        console.log("Saved types: ", data);
    }

    useEffect(() => {
        console.log("Using effect...")
        getPreferences();
    }, []);

    useEffect(() => {
        console.log("Vehicle checks changed: ", vehicleChecks);
        saveTypes();
    }, [vehicleChecks]);

    const handleCheck = (event) => {
        setVehicleChecks({ ...vehicleChecks, [event.target.name]: event.target.checked });
    };

    return (
        <FormItemContainer>
            <h3>Vehicle Types</h3>
            {vehicleTypes.map(type => {
                return (
                    <FormControlLabel
                        key={type}
                        control={<Checkbox checked={vehicleChecks[type]} onChange={handleCheck} name={type} />}
                        label={type}
                    />
                )
            })}
        </FormItemContainer>
    )
}

export default VehicleTypes