import React from 'react'
import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


const apiBaseUrl = "https://prefs.nextgenauction.com"

function RunList(props) {

    const [runList, setRunList] = useState([]);

    const getRunList = async () => {
        let response = await fetch(`${apiBaseUrl}/getRecommendations?dealerKey=${props.dealerKey}&saleKey=${props.saleKey}`)
        let data = await response.json();
        setRunList(data);
    }

    useEffect(() => {
        getRunList();
    }, [props]);

   
return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Lane</TableCell>
                <TableCell align="right">Run</TableCell>
                <TableCell align="right">Year</TableCell>
                <TableCell align="right">Make</TableCell>
                <TableCell align="right">Model</TableCell>
                <TableCell align="right">Mileage</TableCell>
                <TableCell align="right">Link</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {runList.map((row) => (
                <TableRow
                key={`${row.lane}-${row.run}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell align="right">{row.lane}</TableCell>
                <TableCell align="right">{row.run}</TableCell>
                <TableCell align="right">{row.year}</TableCell>
                <TableCell align="right">{row.make}</TableCell>
                <TableCell align="right">{row.model}</TableCell>
                <TableCell align="right">{row.mileage}</TableCell>
                <TableCell align="right"><a href={row.link}>Link</a></TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>);
}

export default RunList