import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormItemContainer from './components/FormItemContainer';
import VehicleTypes from './components/vehicleTypes';
import RunList from './components/runList';


window.apiBaseUrl = 'https://prefs.nextgenauction.com';
const apiBaseUrl = window.apiBaseUrl;

function App() {
  //decode a URI component
  const urlStr = decodeURIComponent(window.location.search);
  //get a value from the urlStr
  const urlParams = new URLSearchParams(urlStr);
  let dealerDisplayName = urlParams.get('dealerDisplayName');
  const base64Encoded = urlParams.get('base64Encoded');
  if (base64Encoded === 'true') {
    dealerDisplayName = atob(dealerDisplayName);
  }
  console.log("Display name: ", dealerDisplayName);
  const dealerKey = urlParams.get('dealerKey');
  const saleKey = urlParams.get('saleKey');

  let [minMiles, setminMiles] = useState(0);
  let [maxMiles, setmaxMiles] = useState(0);
  let [makesString, setmakesString] = useState('');
  let [modelsString, setmodelsString] = useState('');
  let [maxYear, setMaxYear] = useState(0);
  let [minYear, setMinYear] = useState(0);
  let [formDisabled, setFormDisabled] = useState(dealerKey ? false : true);
  const [carsCheck, setCarsCheck] = useState(true);
  const [trucksCheck, setTrucksCheck] = useState(true);
  const [vansCheck, setVansCheck] = useState(true);
  const [suvsCheck, setSUVsCheck] = useState(true);
  const [message, setMessage] = useState('');



  const getPrefs = async () => {
    let response = await fetch(`${apiBaseUrl}/getPrefs?dealerKey=${dealerKey}`)
    let data = await response.json();
    setMinYear(data.minYear);
    setMaxYear(data.maxYear);
    setmakesString(data.makesString);
    setmodelsString(data.modelsString);
    setminMiles(data.minMiles);
    setmaxMiles(data.maxMiles);
    setCarsCheck(Boolean(data.cars));
    setTrucksCheck(Boolean(data.trucks));
    setVansCheck(Boolean(data.vans));
    setSUVsCheck(Boolean(data.suvs));
    console.log(data);  
  }

  const updateMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }
    , 3000);
  }


  const savePrefs = async () => {
    let response = await fetch(`${apiBaseUrl}/setPrefs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dealerKey: dealerKey,
        maxMiles: maxMiles,
        minMiles: minMiles,
        makesString: makesString,
        modelsString: modelsString,
        maxYear: maxYear,
        minYear: minYear,
        cars: carsCheck,
        trucks: trucksCheck,
        vans: vansCheck,
        suvs: suvsCheck
      })
    })
    let data = await response.json();
    if (data.success) {
      updateMessage('Preferences saved!');
    } else {
      updateMessage('Error saving preferences');
    }
    console.log(data);  
  }
  
  useEffect(() => {
    getPrefs();
  }, []);

  const showState = () => {
    console.log(`minMiles: ${minMiles}`);
    console.log(`maxMiles: ${maxMiles}`);
    console.log(`makesString: ${makesString}`);
    console.log(`modelsString: ${modelsString}`);
    console.log(`maxYear: ${maxYear}`);
    console.log(`minYear: ${minYear}`);
    console.log(`cars: ${carsCheck}`);
    console.log(`trucks: ${trucksCheck}`);
    console.log(`vans: ${vansCheck}`);
    console.log(`suvs: ${suvsCheck}`);
  }


  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
    <div className="App" style={{flexDirection: 'column', alignItems: 'center', display: 'flex', width: '60%'}}>
      <h1>{dealerDisplayName}</h1>
      <h2>Preferences</h2>
      <h3 style={{marginTop: 0}}>{message}</h3>  
      <div style={{display: "flex", 
                  flexDirection: "column", 
                  alignItems: "center"}}>
        <div style={{display: "flex",
                     flexDirection: "row",
                      justifyContent: "center",
                      width: "100%"}}>
          <FormItemContainer>
            <TextField
              id="outlined-basic"
              disabled={formDisabled}
              label="Min Miles"
              variant="outlined"
              type='number'
              value={minMiles}
              onChange={(e) => setminMiles(e.target.value)}
            />
          </FormItemContainer>
          <FormItemContainer>
            <TextField
              id="outlined-basic"
              disabled={formDisabled}
              label="Max Miles"
              variant="outlined"
              type='number'
              value={maxMiles}
              onChange={(e) => setmaxMiles(e.target.value)}
            />
          </FormItemContainer>
        </div>
        <div style={{display: "flex",
                     flexDirection: "row",
                      justifyContent: "center",
                      width: "100%"}}>
          <FormItemContainer>
            <TextField
              id="outlined-basic"
              disabled={formDisabled}
              label="Min Year"
              variant="outlined"
              value={minYear}
              type='number'
              onChange={(e) => setMinYear(e.target.value)}
            />                
          </FormItemContainer>
          <FormItemContainer>
            <TextField
              id="outlined-basic"
              disabled={formDisabled}
              label="Max Year"
              variant="outlined"
              value={maxYear}
              type="number"
              onChange={(e) => setMaxYear(e.target.value)}
            />
          </FormItemContainer>
        </div>
        <div style={{display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%"}}>
          <VehicleTypes dealerKey={dealerKey} />
        </div>
      </div>
      <Button onClick={savePrefs} type="submit" variant="contained" color="primary">
                Save Preferences
      </Button>

      <RunList dealerKey={dealerKey} saleKey={saleKey} />
      
    </div>
    </div>
  );
}

export default App;
