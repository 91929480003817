import React from 'react'

function FormItemContainer(props) {
  return (
    <div style={{margin: '10px'}}>
        {props.children}
    </div>
  )
}

export default(FormItemContainer)